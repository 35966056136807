<wuc-card padding="medium">
    <p>
        Hier hoort een video te staan. Deze kunnen wij alleen laden als u uw
        <a
            [routerLink]="['/', appRoutesEnum.Cookies, { outlets: { modal: modalRoutesEnum.CookieSettings } }]"
            rel="nofollow"
        >
            cookie instellingen aanpast
        </a>
        naar "compleet".
    </p>
</wuc-card>
