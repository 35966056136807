import { Component } from '@angular/core';
import { AppRoutesEnum } from '@app/routes';
import { ModalRoutesEnum } from '@app/shared/modals-routed';

@Component({
    selector: 'ins-video-notice',
    templateUrl: './video-notice.component.html',
})
export class VideoNoticeComponent {
    public appRoutesEnum = AppRoutesEnum;
    public modalRoutesEnum = ModalRoutesEnum;
}
