import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TooltipModule } from '@app/shared/tooltip/tooltip.module';
import {
    WucExplanationArrowModule,
    WucIconModule,
    WucPictureModule,
    WucRackModule,
} from '@inshared/website-ui-components';
import { FooterComponent } from './footer.component';

@NgModule({
    declarations: [FooterComponent],
    imports: [
        CommonModule,
        RouterModule,
        TooltipModule,
        WucExplanationArrowModule,
        WucIconModule,
        WucPictureModule,
        WucRackModule,
    ],
    exports: [FooterComponent],
})
export class FooterModule {
}
