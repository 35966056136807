import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromRoot from '@app/store/app.reducer';
import { BreadCrumbItem } from '@app/store/reducers/layout.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ins-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.shared.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
    public breadcrumbs: BreadCrumbItem[] = [];

    private subscriptions: Subscription = new Subscription();

    // eslint-disable-next-line no-useless-constructor
    constructor(private store$: Store<fromRoot.State>) {}

    public ngOnInit(): void {
        // because the breadcrumbs are set inside an Angular lifecycle (e.g. during ngOnInit in index-page.component.ts)
        // an ExpressionChangedAfterItHasBeenCheckedError is thrown
        // Using a setTimeout causes the breadcrumbs to be set ouside the Angular lifecycle
        this.subscriptions.add(
            this.store$
                .select(fromRoot.getLayoutBreadcrumb)
                .subscribe((breadcrumbs: BreadCrumbItem[]) =>
                    setTimeout((): BreadCrumbItem[] => (this.breadcrumbs = breadcrumbs))
                )
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
