<footer class="footer">
    <div class="container">
        <div class="footer__external">
            <section class="footer__external-left">
                <p class="h4 text text--primary">Hallo! Wij zijn InShared</p>
                <p>En wij doen alles net even anders. Samen werken we aan een eerlijke verzekering.</p>
            </section>
            <section class="footer__external-right">
                <wuc-picture
                    insTooltip="Gecertificeerde cloud beveiliging (niveau 2): uw gegevens zijn superveilig bij ons!"
                    imageDesktop="assets/shared/CSA-Star@2x.webp"
                    imageMobile="assets/shared/CSA-Star-icon.webp"
                    [imageWidth]="{ desktop: 133, mobile: 53 }"
                    [imageHeight]="{ desktop: 100, mobile: 40 }"
                    imageType="image/webp"
                    alt="Gecertificeerde cloud beveiliging (niveau 2): uw gegevens zijn superveilig bij ons!"
                ></wuc-picture>
                <a
                    href="https://www.thuiswinkel.org/leden/inshared-nederland-b.v/certificaat"
                    rel="nofollow noopener"
                    title="Thuiswinkel"
                    target="_blank"
                >
                    <wuc-picture
                        imageDesktop="/assets/shared/LVO8-TWW-staand.webp"
                        imageMobile="/assets/shared/LVO8-TWW-staand-icon.webp"
                        [imageWidth]="{ desktop: 102, mobile: 41 }"
                        [imageHeight]="{ desktop: 100, mobile: 40 }"
                        imageType="image/webp"
                        alt="Beste uit de test"
                    ></wuc-picture>
                </a>
                <a
                    class="footer__external-link"
                    href="https://www.wijzeringeldzaken.nl/verzekering/"
                    rel="nofollow noopener"
                    title="Wijzer in geldzaken"
                    target="_blank"
                >
                    <wuc-picture
                        imageDesktop="assets/shared/LVO5-WIG-staand.webp"
                        imageMobile="assets/shared/LVO5-WIG-staand-icon.webp"
                        [imageWidth]="{ desktop: 106, mobile: 42 }"
                        [imageHeight]="{ desktop: 100, mobile: 40 }"
                        imageType="image/webp"
                        alt="Beste uit de test"
                    ></wuc-picture>
                </a>
                <div class="footer__social">
                    <p class="h5 footer__social-title">Volg InShared</p>
                    <svg class="footer__social-arrow svg-icon svg-icon--xl">
                        <use href="assets/icons-general.svg#pijlRR"></use>
                    </svg>
                    <ul class="footer__social-list">
                        <li class="list-group-item">
                            <a
                                href="//www.facebook.com/inshared"
                                class="footer__social-link"
                                title="Facebook"
                                rel="noopener"
                                target="_blank"
                            >
                                <svg class="footer__social-icon svg-icon svg-icon--xl">
                                    <use href="assets/icons-general.svg#facebook"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a
                                href="//twitter.com/inshared"
                                class="footer__social-link"
                                title="Twitter"
                                rel="noopener"
                                target="_blank"
                            >
                                <svg class="footer__social-icon svg-icon svg-icon--xl">
                                    <use href="assets/icons-general.svg#twitter"></use>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
        </div>

        <section class="footer__navigation">
            <ul class="footer__navigation-list">
                <li class="footer__navigation-item">
                    <a routerLink="/verzekeringen">
                        Verzekeringen
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a routerLink="/autoverzekering">
                        Autoverzekering
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a routerLink="/brommerverzekering">
                        Brommer&shy;verzekering
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a routerLink="/inboedelverzekering">
                        Inboedel&shy;verzekering
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a routerLink="/opstalverzekering">
                        Opstalverzekering
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a href="/reisverzekering">
                        Reisverzekering
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a routerLink="/over-inshared">
                        Over InShared
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [href]="'/' + appRoutesEnum.About + '#klachten'">
                        Klachten
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a routerLink="/jaarbeloning">
                        Jaarbeloning
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="[appRoutesEnum.Contact]">
                        Stel uw vraag
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a routerLink="/inloggen">
                        Inloggen
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a href="/schade">
                        Schade
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="[appRoutesEnum.Privacy]">
                        Mijn privacy
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [routerLink]="[appRoutesEnum.Cookies]">
                        Cookies
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a
                        [routerLink]="[appRoutesEnum.Cookies, { outlets: { modal: modalRoutesEnum.CookieSettings } }]"
                        rel="nofollow"
                    >
                        Cookie instellingen
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a
                        [routerLink]="[appRoutesEnum.Privacy, { outlets: { modal: modalRoutesEnum.Disclaimer } }]"
                        rel="nofollow"
                    >
                        Disclaimer
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a [href]="documentsEnum.ConditionsGeneral" target="_blank" rel="nofollow noopener">
                        Voorwaarden
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
                <li class="footer__navigation-item">
                    <a
                        [routerLink]="[appRoutesEnum.Contact, { outlets: { modal: modalRoutesEnum.ResponseTimes } }]"
                        rel="nofollow"
                    >
                        Reactietermijnen
                        <svg class="svg-icon svg-icon--xs">
                            <use href="assets/icons-ui.svg#pijl-right-bold"></use>
                        </svg>
                    </a>
                </li>
            </ul>
        </section>
        <div class="footer__contact">
            <wuc-picture
                imageDesktop="/assets/shared/inshared-onderdeel-achmea.svg"
                [imageWidth]="{ desktop: 300 }"
                [imageHeight]="{ desktop: 30 }"
                imageType="image/svg+xml"
                alt="InShared is een onderdeel van Achmea"
            ></wuc-picture>
        </div>
    </div>
</footer>
