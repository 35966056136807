import { NgModule } from '@angular/core';
import { ProductGradeEffects } from '@app/store/product-grade/product-grade.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CoreReducers, OutSalesModule } from 'outshared-lib';
import { environment } from '../../environments/environment';
import { reducers } from './app.reducer';
import { ElementsEffects } from './effects/elements.effects';

@NgModule({
    imports: [
        OutSalesModule,
        StoreModule.forRoot(reducers, { metaReducers: CoreReducers.metaReducers }),
        EffectsModule.forRoot([
            ElementsEffects,
            ProductGradeEffects,
        ]),
        environment.production ? [] : StoreDevtoolsModule.instrument({connectInZone: true}),
    ],
})
export class AppStoreModule {}
