import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResetService {
    private reset$: Subject<void>;

    constructor() {
        this.reset$ = new Subject();
    }

    public cancelPendingHttpRequests(): void {
        this.reset$.next();
    }

    public getReset(): Observable<void> {
        return this.reset$.asObservable();
    }
}
