import { ModuleWithProviders, NgModule } from '@angular/core';
import { LIVECHAT_CONFIGURATION } from '@app/core/livechat/livechat.configuration';
import { LivechatConfigurationInterface } from '@app/core/livechat/livechat.interfaces';

/** @deprecated - this is moved to libs */
@NgModule()
export class LivechatModule {
    static forRoot(livechatConfiguration?: LivechatConfigurationInterface): ModuleWithProviders<LivechatModule> {
        return {
            ngModule: LivechatModule,
            providers: [{ provide: LIVECHAT_CONFIGURATION, useValue: livechatConfiguration }],
        };
    }
}
