import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieStorage } from '@app/core/storage/cookie-storage.service';
import { LocalStorageService, SessionStorageService, StorageService } from '@app/core/storage/universal.inject';
import { REQUEST } from '../express.tokens';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { LocalStorage } from './core/storage/local-storage.service';
import { SessionStorage } from './core/storage/session-storage.service';

// the Request object only lives on the server
export function getRequest(): any {
    return { headers: { cookie: document.cookie } };
}

@NgModule({
    bootstrap: [AppComponent],
    imports: [BrowserAnimationsModule, AppModule],
    providers: [
        {
            // The server provides these in main.server
            provide: REQUEST,
            useFactory: getRequest,
        },
        { provide: StorageService, useClass: CookieStorage },
        { provide: LocalStorageService, useClass: LocalStorage },
        { provide: SessionStorageService, useClass: SessionStorage },
        { provide: 'ORIGIN_URL', useValue: location.origin },
    ],
})
export class AppBrowserModule {}
