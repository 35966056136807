import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResetInterceptor } from '@app/core/http-interceptors/reset.interceptor';
import { SalesfunnelErrorInterceptor } from '@app/core/http-interceptors/salesfunnel-error.interceptor';
import { ServerErrorInterceptor } from '@app/core/http-interceptors/server-error-interceptor';
import { AuthenticationInterceptor } from 'outshared-lib';
import { HemaActivationAuthenticationInterceptor } from '@app/core/http-interceptors/hema-activation-authentication.interceptor';

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ResetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SalesfunnelErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HemaActivationAuthenticationInterceptor, multi: true },
];
