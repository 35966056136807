import { Injectable } from '@angular/core';
import { ReviewIdEnum } from '@app/core';
import { ProductIdNameService } from '@app/core/services/product-id-name.service';
import * as fromRoot from '@app/store/app.reducer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { NEVER, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ProductGradeKeyValue } from './product-grade-key-value.interface';
import { ProductGradeStoreService } from './product-grade-store.service';
import { LoadProductGrade } from './product-grade.actions';
import * as productGradeActions from './product-grade.actions';
import { shouldLoadProductGrade } from './product-grade.selectors';

@Injectable()
export class ProductGradeEffects {

    getProductGradeByReviewId$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(productGradeActions.LOAD_PRODUCT_GRADE_BY_REVIEW_ID),
        switchMap((action: productGradeActions.LoadProductGradeByReviewId) =>
            this.store.pipe(select(shouldLoadProductGrade(action.payload.reviewId))),
        ),
        switchMap((reviewId: ReviewIdEnum) =>
            reviewId ? of(new LoadProductGrade({reviewId})) : NEVER,
        ),
    ));

    getProductGradeByProductId$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(productGradeActions.LOAD_PRODUCT_GRADE_BY_PRODUCT_ID),
        map((action: productGradeActions.LoadProductGradeByProductId) =>
            ProductIdNameService.getReviewIdByProductId(action.payload.productId)),
        switchMap((reviewId: ReviewIdEnum) =>
            this.store.pipe(select(shouldLoadProductGrade(reviewId))),
        ),
        switchMap((reviewId: ReviewIdEnum) =>
            reviewId ? of(new LoadProductGrade({reviewId})) : NEVER,
        ),
    ));

    getProductGrade$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(productGradeActions.LOAD_PRODUCT_GRADE),
        mergeMap((action: productGradeActions.LoadProductGrade) =>
            this.productGradeStoreService.getProductGrade(action.payload.reviewId),
        ),
        map((productGrade: ProductGradeKeyValue) =>
            new productGradeActions.LoadProductGradeSuccess({productGradeKeyValue: productGrade})),
        catchError((errors) => of(new productGradeActions.LoadProductGradeError({errors}))),
    ));

    constructor(
        private store: Store<fromRoot.State>,
        private actions$: Actions,
        private productGradeStoreService: ProductGradeStoreService,
    ) {
    }
}
