import { ViewportScroller } from '@angular/common';
import { NgModule } from '@angular/core';
import { Event, NavigationEnd, Router, RouterModule, Routes, Scroll, UrlSegment } from '@angular/router';
import { routeConfig } from '@inshared/shared/core';
import { filter, map, pairwise, withLatestFrom } from 'rxjs/operators';
import { RedirectComponent } from './index/redirect.component';
import { MyZoneRoutesEnum } from './my-zone/my-zone-routes.enum';
import { ClaimsRoutingEnum } from './my-zone/pages/claims/claims-routing.enum';
import { AppRoutesEnum } from './routes/app-routes.enum';
import { SalesfunnelRoutes } from '@app/salesfunnel/routing/salesfunnel-routing.routes';

export function SalesfunnelUrlMatcher(url: UrlSegment[]): null | { consumed: UrlSegment[] } {
    const filteredRoutes: string[] = [
        AppRoutesEnum.Activate,
        AppRoutesEnum.Thanks,
    ];

    if (url.length > 0 && filteredRoutes.includes(url[0].path)) {
        return {
            consumed: [],
        };
    }

    if (url.length < 2) {
        return null;
    }

    const regexA = new RegExp(`${AppRoutesEnum.Calculate}-${url[0]}`, 'g');
    const regexB = new RegExp(`${url[0]}-${AppRoutesEnum.Calculate}`, 'g');

    if (url[1].path.match(regexA) || url[1].path.match(regexB)) {
        return {
            consumed: [url[0]],
        };
    }

    return null;
}

export const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./content/index/index.module').then((m) => m.IndexModule),
    },
    {
        path: AppRoutesEnum.ClaimFunnel,
        loadChildren: () => import('./claim-funnel/claim-funnel.module').then((m) => m.ClaimFunnelModule),
    },
    {
        path: AppRoutesEnum.Collectivity,
        loadChildren: () =>
            import('./content/index/collectiviteit/collectiviteit.module').then((m) => m.CollectiviteitModule),
    },
    {
        path: '',
        loadChildren: () => import('./login/login-page/login-page.module').then((m) => m.LoginPageModule),
    },
    {
        path: AppRoutesEnum.Logout,
        loadChildren: () => import('./login/uitloggen/uitloggen.module').then((m) => m.UitloggenModule),
    },
    {
        path: AppRoutesEnum.OptOut,
        loadChildren: () => import('@inshared/shared/feature-opt-out').then((m) => m.OptOutModule),
    },
    {
        path: AppRoutesEnum.My,
        component: RedirectComponent,
    },
    {
        path: AppRoutesEnum.MyZoneOld,
        redirectTo: AppRoutesEnum.MyZone,
    },
    {
        path: AppRoutesEnum.MyZone,
        loadChildren: () => import('./my-zone/my-zone.module').then((m) => m.MyZoneModule),
    },
    {
        path: AppRoutesEnum.Payment,
        loadChildren: () => import('@inshared/my-zone/invoice/feature-payment').then((m) => m.FeaturePaymentModule),
    },
    {
        path: `${AppRoutesEnum.BankAccount}/${AppRoutesEnum.Verify}/${AppRoutesEnum.Status}`,
        loadComponent: () => import('@inshared/shared/feature-payment-verification').then((c) => c.PaymentStatusComponent),
    },
    {
        path: `${AppRoutesEnum.BankAccount}/${AppRoutesEnum.Verify}`,
        pathMatch: 'full',
        loadComponent: () => import('@inshared/shared/feature-payment-verification').then((c) => c.PaymentVerificationPageComponent),
    },
    {
        path: AppRoutesEnum.Avg,
        loadChildren: () => import('./avg/avg.module').then((m) => m.AvgModule),
    },
    // content
    {
        path: AppRoutesEnum.Insurances,
        loadChildren: () => import('./content/verzekeringen/verzekeringen.module').then((m) => m.VerzekeringenModule),
    },
    {
        path: '',
        loadChildren: () => import('./content/overstaphulp/overstaphulp.module').then((m) => m.OverstaphulpModule),
    },
    {
        path: AppRoutesEnum.Damage,
        loadChildren: () => import('./content/schade/schade.module').then((m) => m.SchadeModule),
    },
    {
        matcher: SalesfunnelUrlMatcher,
        loadChildren: () => import('./salesfunnel/salesfunnel.module').then((m) => m.SalesfunnelModule),
    },
    // content
    {
        path: AppRoutesEnum.CarInsurance,
        loadChildren: () =>
            import('./content/autoverzekering/autoverzekering.module').then((m) => m.AutoverzekeringModule),
    },
    {
        path: AppRoutesEnum.BicycleInsurance,
        loadChildren: () =>
            import('./content/fietsverzekering/fietsverzekering.module').then((m) => m.FietsverzekeringModule),
    },
    {
        path: AppRoutesEnum.MopedInsurance,
        loadChildren: () =>
            import('./content/brommerverzekering/brommerverzekering.module').then((m) => m.BrommerverzekeringModule),
    },
    {
        path: AppRoutesEnum.ScooterInsurance,
        loadChildren: () =>
            import('./content/scooterverzekering/scooterverzekering.module').then((m) => m.ScooterverzekeringModule),
    },
    {
        path: AppRoutesEnum.MotorInsurance,
        loadChildren: () =>
            import('./content/motor-insurance/motor-insurance.module').then((m) => m.MotorInsuranceModule),
    },
    {
        path: AppRoutesEnum.TravelInsurance,
        loadChildren: () =>
            import('./content/reisverzekering/reisverzekering.module').then((m) => m.ReisverzekeringModule),
    },
    {
        path: AppRoutesEnum.HomeInsurance,
        loadChildren: () =>
            import('./content/woonverzekering/woonverzekering.module').then((m) => m.WoonverzekeringModule),
    },
    {
        path: AppRoutesEnum.InventoryInsurance,
        loadChildren: () =>
            import('./content/woonverzekering/inboedelverzekering.module').then((m) => m.InboedelverzekeringModule),
    },
    {
        path: AppRoutesEnum.BuildingInsurance,
        loadChildren: () =>
            import('./content/woonverzekering/opstalverzekering.module').then((m) => m.OpstalverzekeringModule),
    },
    {
        path: AppRoutesEnum.LegalInsurance,
        loadChildren: () =>
            import('./content/rechtsbijstandverzekering/rechtsbijstandverzekering.module').then(
                (m) => m.RechtsbijstandverzekeringModule
            ),
    },
    {
        path: AppRoutesEnum.About,
        loadChildren: () => import('./content/over-inshared/over-inshared.module').then((m) => m.OverInsharedModule),
    },
    {
        path: AppRoutesEnum.CaravanInsurance,
        loadChildren: () =>
            import('./content/caravan-insurance/caravan-insurance.module').then((m) => m.CaravanInsuranceModule),
    },
    {
        path: AppRoutesEnum.AccidentInsurance,
        loadChildren: () =>
            import('./content/accident-insurance/accident-insurance.module').then((m) => m.AccidentInsuranceModule),
    },
    {
        path: AppRoutesEnum.LiabilityInsurance,
        loadChildren: () =>
            import('./content/aansprakelijkheidsverzekering/aansprakelijkheidsverzekering.module').then(
                (m) => m.AansprakelijkheidsverzekeringModule
            ),
    },
    {
        path: AppRoutesEnum.PetInsurance,
        loadChildren: () => import('./content/pet-insurance/pet-insurance.module').then((m) => m.PetInsuranceModule),
    },
    {
        path: AppRoutesEnum.CatInsurance,
        loadChildren: () =>
            import('./content/pet-insurance/cat-insurance/cat-insurance.module').then((m) => m.CatInsuranceModule),
    },
    {
        path: AppRoutesEnum.DogInsurance,
        loadChildren: () =>
            import('./content/pet-insurance/dog-insurance/dog-insurance.module').then((m) => m.DogInsuranceModule),
    },
    {
        path: AppRoutesEnum.DamageRepair,
        loadChildren: () => import('./content/schadeherstel/schadeherstel.module').then((m) => m.SchadeherstelModule),
    },
    {
        path: '',
        loadChildren: () => import('./content/contact/contact.module').then((m) => m.ContactModule),
    },
    {
        path: '',
        loadChildren: () => import('./content/cookies/cookies.module').then((m) => m.CookiesModule),
    },
    {
        path: AppRoutesEnum.AnnualReward,
        loadChildren: () => import('./content/jaarbeloning/jaarbeloning.module').then((m) => m.JaarbeloningModule),
    },
    {
        path: AppRoutesEnum.CustomerSatisfaction,
        loadChildren: () =>
            import('./content/hoe-tevreden-zijn-klanten/hoe-tevreden-zijn-klanten.module').then(
                (m) => m.HoeTevredenZijnKlantenModule
            ),
    },
    {
        path: '',
        loadChildren: () => import('./content/privacy/privacy.module').then((m) => m.PrivacyModule),
    },
    {
        path: AppRoutesEnum.DamageReport,
        loadChildren: () =>
            import('./content/schade/schadeverslag/schadeverslag.module').then((m) => m.SchadeverslagModule),
    },
    {
        path: AppRoutesEnum.Prevention,
        loadChildren: () => import('./content/preventie/preventie.module').then((m) => m.PreventieModule),
    },
    {
        path: AppRoutesEnum.HemaActivation,
        loadChildren: () =>
            import('@inshared/authentication/feature-hema-activation').then((m) => m.HemaActivationRoutingModule),
    },
    {
        path: AppRoutesEnum.PageNotFound,
        loadChildren: () => import('./content/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
    },
    {
        path: '**',
        redirectTo: AppRoutesEnum.PageNotFound,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            useHash: false,
            enableTracing: false,
            initialNavigation: 'enabledBlocking',
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'disabled',
            anchorScrolling: 'enabled',
        }),
    ],
    providers: [
        {
            provide: routeConfig,
            useValue: {
                app: AppRoutesEnum,
            },
        },
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(router: Router, viewportScroller: ViewportScroller) {
        AppRoutingModule.scrollToTopOnForwardNavigation(router, viewportScroller);
    }

    static scrollToTopOnForwardNavigation(router: Router, viewportScroller: ViewportScroller): void {
        const scrollEvents$ = router.events.pipe(
            filter((event: Event) => event instanceof Scroll),
            map((event: Event) => event as Scroll)
        );

        // Emits the previous URL after the router navigates to a new URL
        const originUrl$ = router.events.pipe(
            filter((event: Event) => event instanceof NavigationEnd),
            map((event: Event) => event as NavigationEnd),
            pairwise(),
            map((navigationEvents) => navigationEvents)
        );

        scrollEvents$
            .pipe(withLatestFrom(originUrl$))
            .subscribe(([scrollEvent, navigationEvents]) =>
                this.handleScrollNavigationEvent(viewportScroller, scrollEvent, navigationEvents)
            );
    }

    static handleScrollNavigationEvent(
        viewportScroller: ViewportScroller,
        scrollEvent: Scroll,
        navigationEvents: [NavigationEnd, NavigationEnd]
    ): void {
        const [previousNavigationEvents, nextNavigationEvents] = navigationEvents;
        const urls: string[] = this.getUrlList(nextNavigationEvents);
        const activeRoute: string = nextNavigationEvents.url.split('?')[0];

        if (scrollEvent.position) {
            // backward navigation
            viewportScroller.scrollToPosition(scrollEvent.position);
        } else if (scrollEvent.anchor) {
            // anchor navigation
            viewportScroller.scrollToAnchor(scrollEvent.anchor);
        } else if (!urls.includes(activeRoute)) {
            // forward navigation from non routes
            viewportScroller.scrollToPosition([0, 0]);
        }
    }

    static getUrlList(nextNavigationEvents: NavigationEnd): string[] {
        const urls = [
            `/${AppRoutesEnum.MyZone}/${MyZoneRoutesEnum.MyInsurances}`,
            `/${AppRoutesEnum.MyZone}/${MyZoneRoutesEnum.MyData}`,
            // TODO: temporary path to be able to see/test/develop the new lib
            // https://jira.outshared.com/browse/WINS-10603
            // also apps/inshared-nl/src/app/my-zone/my-zone-routing.module.ts
            `/${AppRoutesEnum.MyZone}/${MyZoneRoutesEnum.MyInsurances}-lib`,
            `/${AppRoutesEnum.MyZone}/${MyZoneRoutesEnum.SelfService}/${MyZoneRoutesEnum.Cancel}`,
            `/${AppRoutesEnum.MyZone}/${MyZoneRoutesEnum.MyClaims}`,
            `/${AppRoutesEnum.MyZone}/${MyZoneRoutesEnum.MyClaims}/${ClaimsRoutingEnum.DamageClaim}`,
        ];
        const url = nextNavigationEvents.url.split('?');

        if (url.length === 1) {
            urls.pop();
        }

        return urls;
    }
}
