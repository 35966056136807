import { Component } from '@angular/core';
import { DocumentsEnum } from '@app/core/enums/documents.enum';
import { AppRoutesEnum } from '@app/routes';
import { ModalRoutesEnum } from '@app/shared/modals-routed/modal-routes.enum';

@Component({
    selector: 'footer-component',
    templateUrl: 'footer.component.html',
    styleUrls: ['./footer.component.scss'],
})

export class FooterComponent {
    public documentsEnum = DocumentsEnum;
    public appRoutesEnum = AppRoutesEnum;
    public modalRoutesEnum = ModalRoutesEnum;
}
