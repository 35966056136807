import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ReviewIdEnum } from '@app/core';
import { ProductGrade } from '@app/core/interfaces';
import { Response } from '@app/core/interfaces/response';
import { RequestService } from '@app/core/request/request.service';
import { ProductIdNameService } from '@app/core/services/product-id-name.service';
import { ProductIdEnum } from 'outshared-lib';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductGradeKeyValue } from './product-grade-key-value.interface';

@Injectable({
    providedIn: 'root',
})
export class ProductGradeStoreService {

    constructor(
        private request: RequestService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
    }

    /**
     * Only to be used by the store effect to request productGrade from api
     * Use ProductGradeService to request and get grades from within the application
     */
    public getProductGrade(reviewId: ReviewIdEnum): Observable<ProductGradeKeyValue | null> {
        if (isPlatformServer(this.platformId)) {
            return of(null);
        }

        const ids = ProductIdNameService.combineWithOldReviewId(reviewId);

        const payload = {
            vragenset: ids.map((id: number) => {
                return {vragenset_id: id};
            }),
        };

        return this.request.post('reviews-algemeen/opvragen/aggregate', payload).pipe(
            map((response: Response) => response.data),
            map((data: any) => {
                const parts = data.gemiddelde_score?.split('.');
                return {
                    totaal: data.totaal,
                    gemiddelde_score: {
                        score: data.gemiddelde_score,
                        tiental: parts[0],
                        decimaal: parts[1],
                    },
                };
            }),
            map((value: ProductGrade | null) => {
                return {
                    key: reviewId,
                    value: value,
                };
            }),
        );
    }

}
