import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as fromElements from '../actions/elements.actions';
import { ErrorOccurred } from '../actions/errors.actions';
import * as fromRoot from '../app.reducer';
import { ElementsService } from './elements.service';

/** @deprecated moved to `@inshared/shared/data-access-elements` in libs folder */
@Injectable()
export class ElementsEffects {

    private getElementIds$: Observable<string[]> = this.store.pipe(select(fromRoot.getElementIds));

    loadElements$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(fromElements.ADD),
        map((action: fromElements.AddElement) => action),
        withLatestFrom(this.getElementIds$),
        mergeMap(([action, loaded]) => {
            if (loaded.includes(action.payload)) {
                return EMPTY;
            }

            return this.elementsService.getElement$(action.payload).pipe(
                map((element) => new fromElements.AddElementSuccess(element)),
                catchError((error) => of(new ErrorOccurred({ action, error }))),
            );
        }),
    ));

    constructor(
        private actions$: Actions,
        private elementsService: ElementsService,
        private store: Store<fromRoot.State>,
    ) { }
}
