import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as fromRoot from '@app/store/app.reducer';
import { AppConfigService } from '@inshared/shared/core';
import { Store } from '@ngrx/store';
import {
    AuthenticationDataService,
    NotificationInterface,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { Observable, Subscription, of } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

declare var Cynolytics;

/** @deprecated - this is moved to libs */
@Injectable({
    providedIn: 'root',
})
export class CynolyticsService implements OnDestroy {
    private renderer: Renderer2;
    private relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();
    private subscription: Subscription = new Subscription();
    private cynolyticsUrl: string;

    constructor(
        private rendererFactory: RendererFactory2,
        private store: Store<fromRoot.State>,
        private router: Router,
        private relationDataService: RelationDataService,
        private authenticationDataService: AuthenticationDataService,
        private appConfigService: AppConfigService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public init(): void {
        this.cynolyticsUrl = this.appConfigService.getConfig().cynolyticsUrl;

        if (typeof _cs === 'undefined' || !Boolean(this.cynolyticsUrl)) {
            return;
        }

        this.assignConfig();
        this.addCynoLyticsScript();
        this.addSubscriptions();
    }

    public sendMessageToCynoLytics(data: any): void {
        if (typeof cl === 'undefined') {
            return;
        }

        const payload = {
            melding_categorie: data.error.meldingen[0].soort,
            boodschap_code: data.error.meldingen[0].boodschap_code,
            boodschap_inhoud: data.error.meldingen[0].boodschap_inhoud,
            product_id: 'AUTO',
            event_naam: '',
        };

        cl('send', 'meldingen', payload);
    }

    public sendNotificationsToCynoLytics(productId: string, notifications: NotificationInterface[]): void {
        if (typeof cl === 'undefined') {
            return;
        }

        notifications.forEach((notification: NotificationInterface) => {
            cl('send', 'melding', {
                melding_categorie: notification.notification_type,
                boodschap_code: notification.message_code,
                boodschap_inhoud: notification.message_content,
                product_id: productId,
                event_naam: 'melding_backend',
            });
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private assignConfig(): void {
        Object.assign(_cs, {
            cynolytics_url: this.cynolyticsUrl,
            cynolytics_partner_id: '00001',
            taal_id: 'nl-NL',
        });
    }

    private addSubscriptions(): void {
        const relation$ = this.relation$.pipe(
            map((relation) => relation?.relation_id),
            tap((relationId) => {
                if (typeof _cs !== 'undefined' && typeof Cynolytics !== 'undefined') {
                    Cynolytics.setRelatieId(relationId);
                }
            })
        );

        // TODO: In de datalayer zit ook een pageview, wat is het verschil?
        const pageViewSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => ({ url_pad: event.urlAfterRedirects })),
                withLatestFrom(this.authenticationDataService.isLoggedIn$()),
                switchMap(([data, isLoggedIn]) =>
                    !isLoggedIn
                        ? of(data)
                        : relation$.pipe(
                              filter((relationId) => !!relationId),
                              map(() => data)
                          )
                )
            )
            .subscribe((data) => this.sendToCynoLytics(data));

        this.subscription.add(pageViewSubscription);
    }

    private sendToCynoLytics(data: any): void {
        if (typeof cl === 'undefined') {
            return;
        }

        cl('send', 'pageview', data);
    }

    private addCynoLyticsScript(): void {
        const csa = this.renderer.createElement('script');

        this.renderer.setAttribute(csa, 'type', 'text/javascript');
        this.renderer.setAttribute(csa, 'src', `${_cs.cynolytics_url}/js/cs_min.js`);
        this.renderer.setProperty(csa, 'async', true);

        const x = this.document.getElementsByTagName('script')[0];

        x.parentNode.insertBefore(csa, x);
    }
}
