<ol
    *ngIf="(breadcrumb$ | async)?.length"
    itemscope
    itemtype="https://schema.org/BreadcrumbList"
    class="breadcrumb breadcrumb--bordered"
>
    <li
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
        *ngFor="let item of breadcrumb$ | async; index as breadcrumbIndex"
    >
        <a itemprop="item" *ngIf="item.href" href="{{ item.href }}" title="{{ item.title }}">
            <span itemprop="name">{{ item.title }}</span>
        </a>
        <a itemprop="item" *ngIf="item.routerLink" [routerLink]="item.routerLink" title="{{ item.title }}">
            <span itemprop="name">{{ item.title }}</span>
        </a>
        <a
            itemprop="item"
            *ngIf="!item.routerLink && !item.href"
            [href]="router.url"
            class="breadcrumb__link-fake"
            (click)="onClick($event)"
        >
            <span itemprop="name">{{ item.title }}</span>
        </a>
        <meta itemprop="position" [content]="breadcrumbIndex" />
    </li>
</ol>
