import { NgModule } from '@angular/core';
import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbBorderedComponent } from './breadcrumb-bordered.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        BreadcrumbComponent,
        BreadcrumbBorderedComponent
    ],
    exports: [
        BreadcrumbComponent,
        BreadcrumbBorderedComponent
    ],
    providers: [],
    schemas: []
})
export class BreadcrumbModule {
}
