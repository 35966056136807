import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ins-redirect',
    template: ''
})

export class RedirectComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription();

    constructor(
        public route: ActivatedRoute,
        public router: Router
    ) {
        const fragment = this.route
            .fragment
            .pipe(take(1))
            .subscribe(url => this.router.navigateByUrl(url));
        this.subscriptions.add(fragment);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
