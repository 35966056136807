import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResetService } from '@app/core/authentication/reset.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ResetInterceptor implements HttpInterceptor {
    private readonly reset$: Observable<void>;

    constructor(private resetService: ResetService) {
        this.reset$ = this.resetService.getReset();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                takeUntil(this.reset$),
            );
    }
}
