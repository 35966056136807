import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CynolyticsService } from '@app/core/analytics/cynolytics.service';
import { SalesfunnelService } from '@app/salesfunnel/salesfunnel.service';
import { DatalayerService } from 'outshared-lib';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class SalesfunnelErrorInterceptor implements HttpInterceptor {

    constructor(
        private cynoLyticsService: CynolyticsService,
        private datalayerService: DatalayerService,
        private salesfunnelService: SalesfunnelService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (isPlatformServer(this.platformId)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            tap((response: HttpResponse<any>) => {
                if ((response.status === 200 || response.status === 500) && response.url.includes('/api/v2')) {
                    if (response.body.notifications) {
                        this.cynoLyticsService.sendNotificationsToCynoLytics('AUTO', response.body.notifications);
                        this.datalayerService.sendNotificationsToDatalayer(response.body.notifications);

                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (this.salesfunnelService.isSalesfunnel(request.url)) {
                    this.cynoLyticsService.sendMessageToCynoLytics(error);
                }

                return throwError(error);
            }));
    }
}
