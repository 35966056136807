import { Injectable, Inject, Renderer2, RendererFactory2, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class FeatureDetectionService {

    public renderer: Renderer2;

    constructor(
        @Inject(DOCUMENT) public document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
        public rendererFactory: RendererFactory2,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public init(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        this.testWebP().then((hasWebP: boolean) => {
            if (hasWebP) {
                this.renderer.addClass(this.document.body, 'image-webp');
            } else {
                this.renderer.addClass(this.document.body, 'image-no-webp');
            }
        }).catch(() => {
            this.renderer.addClass(this.document.body, 'image-no-webp');
        });
    }

    private testWebP() {
        return new Promise(res => {
            const webP = new Image();
            webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
            webP.onload = webP.onerror = () => {
                res(webP.height === 2);
            };
        });
    }
}
