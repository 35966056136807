import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const SOAP_ERROR_NL = 'Oeps er gaat iets mis, probeert u het later nog een keer';
const SOAP_ERROR_EN = 'Oops, something goes wrong, please try again later';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

    private error: HttpErrorResponse;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (isPlatformServer(this.platformId)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status !== 500) {
                    throw error;
                }

                if (this.validateDutchApiRequest(error) || this.validateEngApiRequest(error)) {
                    throw this.error;
                }
                throw error;
            }),
        );
    }

    private validateDutchApiRequest(errorResponse: HttpErrorResponse): boolean {
        if (errorResponse.error && errorResponse.error.meldingen.length > 0) { // NL
            this.error  = {
                ...errorResponse,
                error: {meldingen: [{boodschap_inhoud: SOAP_ERROR_NL}]},
                message: SOAP_ERROR_NL,
            };

            return true;
        }

        return false;
    }

    private validateEngApiRequest(errorResponse: HttpErrorResponse): boolean {
        if (errorResponse.error && errorResponse.error.notifications.length > 0) { // ENG
            this.error = {
                ...errorResponse,
                error: {notifications: [{message_content: SOAP_ERROR_EN}]},
                message: SOAP_ERROR_EN,
            };
            return true;
        }

        return false;
    }
}
