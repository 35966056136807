import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as fromRoot from '@app/store/app.reducer';
import { BreadCrumbItem } from '@app/store/reducers/layout.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-breadcrumb-bordered',
    templateUrl: './breadcrumb-bordered.component.html',
    styleUrls: ['./breadcrumb.shared.scss'],
})
export class BreadcrumbBorderedComponent {

    public breadcrumb$: Observable<BreadCrumbItem[]> = this.store.select(fromRoot.getLayoutBreadcrumb);

    constructor(
        private store: Store<fromRoot.State>,
        public router: Router,
    ) {}

    onClick(event: MouseEvent): void {
        event.preventDefault();
    }
}
