import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VideoNoticeComponent } from '@app/shared/ux/video-notice/video-notice.component';
import { WucCardModule } from '@inshared/website-ui-components';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        WucCardModule,
    ],
    declarations: [
        VideoNoticeComponent,
    ],
    exports: [
        VideoNoticeComponent,
    ],
})
export class VideoNoticeModule {
}
