import { Inject, Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AppRoutesEnum } from '@inshared/shared/core';
import { AuthenticationDataService, AuthenticationStatusEnum, HttpStatusEnum } from 'outshared-lib';
import { WINDOW } from '@inshared/shared/util';

enum HemaActivationRoutesEnum {
    TokenProcessing = 'direct-regelen',
    ChangePassword = 'wachtwoord-wijzigen',
    ProvideEmail = 'email-opgeven',
}

@Injectable()
export class HemaActivationAuthenticationInterceptor implements HttpInterceptor {
    private flowType: AuthenticationStatusEnum;
    private hemaActivationUrls = [`/${AppRoutesEnum.HemaActivation}/${HemaActivationRoutesEnum.TokenProcessing}`];

    constructor(
        private router: Router,
        @Inject(WINDOW) private window: Window,
        private authenticationDataService: AuthenticationDataService
    ) {
        this.authenticationDataService.getStatus$().subscribe((status) => {
            this.flowType = status;
        });
    }

    private isHemaActivationRoute() {
        const currentUrl = this.window.location.href;
        const url = new URL(currentUrl);
        const path = url.pathname;
        return this.hemaActivationUrls.includes(path);
    }

    private handleAuthenticationError() {
        if (this.isHemaActivationRoute() && this.flowType === AuthenticationStatusEnum.ChangeEmail) {
            this.router.navigate([`/${AppRoutesEnum.HemaActivation}`, `${HemaActivationRoutesEnum.ProvideEmail}`]);
        }
        if (this.isHemaActivationRoute() && this.flowType === AuthenticationStatusEnum.TemporaryPassword) {
            this.router.navigate([`/${AppRoutesEnum.HemaActivation}`, `${HemaActivationRoutesEnum.ChangePassword}`]);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === HttpStatusEnum.Unauthorized || error.status === HttpStatusEnum.Forbidden) {
                    this.handleAuthenticationError();
                }
                return throwError(error);
            })
        );
    }
}
